<template>
  <v-dialog persistent v-model="dialog" width="500">
    <PreviousPurchase @add="addPreviousPurchase" :PurchaseIMEIResult="PurchaseIMEIResult" @close="purchaseDialogClose"
      :purchaseDialog="purchaseDialog" />
    <v-card>
      <ModelTitle :title="title" @close="close()" />
      <v-progress-linear class="mt-0 mb-0" background-color="pink lighten-5" color="pink lighten-1" height="2"
        v-model="reOrderPercentage">
      </v-progress-linear>
      <v-container grid-list-md>
        <v-layout row align-center>
          <v-flex>
            <v-textarea v-if="bulkEntry" outlined rows="1" v-model="bulkSerialNumber"></v-textarea>
            <v-text-field v-else id="serialNumberBox" @keyup.enter.native="enterSerial()" label="Serial Number"
              :loading="serailLoading" ref="serialNumber" clearable outlined :disabled="serailLoading" :counter="length"
              autofocus dense @focus="$event.target.select()" v-model.trim="serialNumber"></v-text-field>
          </v-flex>
          <!-- :disabled="!serialNumber" -->
          <v-flex shrink align-self-start="">
            <v-btn large color="info" @click="enterSerial"> add </v-btn>
          </v-flex>
        </v-layout>
        <!-- <v-divider class="mt-2"></v-divider> -->
        <v-list class="list-350" >
          <template v-for="(row, index) in serialNumberList">
            <v-list-item :key="index">
              <v-list-item-avatar>
                <v-btn icon>
                  <v-icon color="red" @click="deleteNumber(index)">delete</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="row">
                  <span :style="{ color: row.length != length ? 'red' : 'black' }">{{ row }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-container>


      <v-card-text v-if="serialNumberList.length">
        <v-btn color="error" block @click="clearAll">Clear All</v-btn>
      </v-card-text>
      

      <v-divider></v-divider>
      <v-card-actions>
        <v-row align="center">
          <v-col>
            <v-text-field class="right-input" @focus="$event.target.select()" label="Length" :prepend-inner-icon="
              lock ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline'
            " @click:prepend-inner="lockStatus" v-model="length" outlined hide-details dense></v-text-field>
          </v-col>
          <v-col>
            <v-text-field class="right-input" @focus="$event.target.select()" label="Unit Cost" v-model="cost" outlined
              hide-details dense></v-text-field>
          </v-col>
          <v-col>
            <v-switch v-model="bulkEntry" label="Bulk"></v-switch>
          </v-col>

          <v-col>
            <v-btn v-if="getEditImeiNumberList.type === 'new'" color="primary" large
              :disabled="serialNumberList.length === 0" text @click="saveSerialRows">
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <LengthExceeded @close="lengthExceeded = false" :lengthExceeded="lengthExceeded" :serialNumber="serialNumber" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import purchaseService from "../service";
import PreviousPurchase from "@/modules/Inventory/Purchase/components/PreviousPurchase.vue";
import LengthExceeded from "@/modules/Inventory/Purchase/components/LengthExceeded.vue";

export default {
  name: "collect-imeinumber",
  data() {
    return {
      currentIndex: 0,
      bulkEntry: false,
      bulkSerialNumber: null,
      bulkSerialNumberList: [],
      lengthExceeded: false,
      lock: true,
      PurchaseIMEIResult: null,
      purchaseDialog: false,
      dialog: false,
      serailLoading: false,
      serialNumber: null,
      length: 15,
      cost: 0,
      qty: 0,
      serialNumberList: [],
      reOrderPercentage: 0,
    };
  },
  components: {
    PreviousPurchase,
    LengthExceeded,
  },
  computed: {
    ...mapGetters("purchase", [
      "getImeiNumberDialog",
      "getEditImeiNumberList",
      "PurchaseDetails",
      "getSelectedProduct",
      "getEditImeiNumberList",
    ]),
    title() {
      return `${this.serialNumberList.length} / ${this.qty} -  ${this.getSelectedProduct.name}`;
    },
  },
  watch: {
    bulkEntry() {
      this.serialNumber = null;
    },
    // dialog(val) {
    //   if (val) {
    //     this.serialNumberList = null;
    //   }
    // },
    serialNumberList(val) {
      if (val.length == this.qty) {
        if (!this.bulkEntry) {
          this.saveSerialRows();
        }
      }
    },
    getImeiNumberDialog(val) {
      this.dialog = val;
      if (val) {
        if (this.getEditImeiNumberList.type === "new") {
          this.cost = this.getSelectedProduct.Bar.cost;
          this.qty = this.getSelectedProduct.Bar.qty;
        } else {
          this.loadNumbers();
        }
      }
    },
  },
  methods: {
    ...mapActions("purchase", [
      "AddPurchaseDetail",
      "SetImeiNumberDialog",
      "UpdatePurchaseDetail",
    ]),
    clearAll() {
      this.$swal({
        title: "Are you sure?",
        text: `You have ${this.serialNumberList.length} serial in this list. You want to remove them?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.serialNumberList = []
        }
      });
    },
    closeLengthDialog() {
      this.lengthExceeded = false;
      setTimeout(function () {
        if (this.$refs && this.$refs.serialNumber)
          this.$refs.serialNumber.focus();
      }, 300);
    },
    lockStatus() {
      this.lock = !this.lock;
    },
    viewPrucaseDialog() {
      const url = `/global/purchase/${this.PurchaseIMEIResult.Purchase.id}/view`;

      const routeData = this.$router.resolve({
        path: url,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    deleteNumber(i) {
      this.serialNumberList.splice(i, 1);
    },
    loadNumbers() {
      const index = this.getEditImeiNumberList.index;
      this.cost = parseFloat(this.PurchaseDetails[index].cost);
      this.serialNumberList = this.PurchaseDetails[index].imeiNumbers;
    },
    close() {
      this.serialNumber = null;
      this.bulkSerialNumber = null;
      this.bulkSerialNumberList = [];
      this.bulkEntry = false;
      this.SetImeiNumberDialog(false);
      this.$emit("close");
    },
    emptyEntryBar() {
      this.serialNumberList = [];
      this.cost = 0;
      this.reOrderPercentage = 0;
      setTimeout(function () {
        if (this.$refs && this.$refs.serialNumber)
          this.$refs.serialNumber.focus();
      }, 300);
    },
    // async updateSerialRows() {
    //   await this.UpdatePurchaseDetail({
    //     index: this.getEditImeiNumberList.index,
    //     imeiNumbers: this.serialNumberList,
    //     qty: this.serialNumberList.length,
    //     cost: this.cost,
    //     total: this.serialNumberList.length * this.cost,
    //   });

    //   this.emptyEntryBar();
    //   this.dialog = false;
    // },
    purchaseDialogClose() {
      this.serialNumber = null;
      this.purchaseDialog = false;
      this.bulkEntry = false;
      this.bulkSerialNumber = null;
      this.bulkSerialNumberList = [];
      setTimeout(() => {
        this.$refs.serialNumber.focus();
      }, 100);
    },
    async saveSerialRows() {
      // // console.log("saveSerialRows", this.serialNumberList.length);

      for (let index = 0; index < this.serialNumberList.length; index++) {
        const x = this.serialNumberList[index];
        const row = {
          ProductId: this.getSelectedProduct.id,
          name: this.getSelectedProduct.name,
          imeiNumber: x,
          cost: this.cost,
          total: this.cost,
          qty: 1,
          Product: this.getSelectedProduct,
          metadata: {
            serialStatus: this.getSelectedProduct.Bar.status
          }
        };
        // console.log("row adding", index, this.serialNumberList.length);
        this.AddPurchaseDetail(row);
        if (index >= this.serialNumberList.length - 1) {
          this.emptyEntryBar();
          this.close();
        }
      }
      // await Promise.all(this.serialNumberList.map((x) => {
      //   const row = {
      //     ProductId: this.getSelectedProduct.id,
      //     name: this.getSelectedProduct.name,
      //     imeiNumber: x,
      //     cost: this.cost,
      //     total: this.cost,
      //     qty: 1,
      //     Product: this.getSelectedProduct,
      //   };
      //   // console.log("row adding", row);
      //   this.AddPurchaseDetail(row);
      // }))
    },
    enterSerial() {
      if (this.bulkEntry) {
        if (!this.bulkSerialNumber) return false;
        this.bulkSerialNumberList = this.bulkSerialNumber.split("\n");
        let uniq = [...new Set(this.bulkSerialNumberList)];
        console.log('uniq', uniq)
        for (let index = 0; index < uniq.length; index++) {
          const element = this.bulkSerialNumberList[index];
          this.currentIndex = index;
          this.serialNumber = element;
          if (element) {
            this.addSerial(element);
          }

        }
        this.bulkSerialNumber = null;
      } else {
        this.addSerial(this.serialNumber);
      }
    },
    async addSerial(sNumber) {
      if (!sNumber) return false;
      if (this.lock && sNumber.length !== parseInt(this.length)) {
        this.lengthExceeded = true;
        return false;
      }

      // // console.log("serialNumber", sNumber);

      let tempSerialNumberList = [];

      tempSerialNumberList = this.serialNumberList;

      let index = tempSerialNumberList.indexOf(sNumber);

      const imeiResult = this.PurchaseDetails.find((row) => {
        if (!row.delete && row.imeiNumber === sNumber) {
          return true;
        }
      });

      if (imeiResult || index != -1) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
          })
          .fire({
            icon: "error",
            title: `${sNumber} is already in this list`,
          });
        const input = document.getElementById("serialNumberBox");
        if (input) {
          input.select();
        }
        
        // this.$swal({
        //   title: "Already exist!",
        //   text: `${this.serialNumber} is already in this list`,
        //   icon: "warning",
        // });
        if (!this.bulkEntry) {
          this.$refs.serialNumber.focus();
        }
      } else {
        this.serailLoading = true;
        try {
          this.PurchaseIMEIResult = (
            await purchaseService.search({
              imeiNumber: sNumber,
              ignoreStatus: true,
            })
          ).data;

          // console.log("imeiResult", this.PurchaseIMEIResult, this.bulkEntry);
          if (this.bulkEntry) {
            this.addPreviousPurchase(sNumber);
          } else {
             if (this.PurchaseIMEIResult) {
            this.purchaseDialog = true;
          } else {
            // console.log("add previous purchase in IMEI");
            this.addPreviousPurchase(sNumber);
          }
          }

         

          this.serailLoading = false;
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    addPreviousPurchase(sNumber) {
      // console.log("serialNumberList", sNumber, this.serialNumber, this.bulkEntry);
      if (!sNumber) sNumber = this.serialNumber;

      this.serialNumberList.unshift(sNumber);
      this.reOrderPercentage =
        (this.serialNumberList.length / this.getSelectedProduct.Bar.qty) * 100;
      this.serialNumber = null;

      if (!this.bulkEntry) {
        this.$refs.serialNumber.focus();
        this.purchaseDialogClose();
      } 

      // if (this.PurchaseIMEIResult[0].qty > 0) {

      // }
    },
  },
};
</script>

<style scoped>

</style>
