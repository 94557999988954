<template>
<v-dialog v-model="dialog" width="350">
      <v-card>
        <ModelTitle title="Length Exceeded" @close="close" />

        <v-card-text class="pt-5 pb-0">
          <div class="text-center">
            <v-avatar color="red">
              <v-icon dark>mdi-lock-outline</v-icon>
            </v-avatar>

            <p class="mt-3">
              <span class="font-weight-bold">{{ serialNumber }}</span> length
              exceeded
            </p>
            <p>
              if you like to unlock length restriction click lock icon in length
              text box
            </p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" autofocus text @click="close">
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props:['lengthExceeded', 'serialNumber'],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    lengthExceeded(val) {
      this.dialog = val;
    },
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>