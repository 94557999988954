<template>
  <v-dialog persistent v-model="dialog" width="650">
    <v-card v-if="PurchaseIMEIResult">
      <ModelTitle title="Found Previous Purchase" @close="close" color="red" :dark="true" />
      <v-card-text v-if="PurchaseIMEIResult" class="pt-5">
        <div>
          <span>Item Name: </span>
          <span class="font-weight-bold">{{
              PurchaseIMEIResult.Product.name
          }}</span>
        </div>
        <div>
          <span>Serial Number: </span>
          <span class="font-weight-bold">{{
              PurchaseIMEIResult.imeiNumber
          }}</span>
        </div>
      </v-card-text>
      <v-card-text>
        <div class="text-center">
          <div>
            <span class="ml-2" v-if="PurchaseIMEIResult">
              <v-chip class="text-capitalize" :color="PurchaseIMEIResult.status === 'sold' ? 'red' : 'teal'"
                text-color="white">
                {{ PurchaseIMEIResult.status }}
              </v-chip>
            </span>
          </div>
          <span v-if="PurchaseIMEIResult" class="font-weight-bold">This item was purchase before, do you still want to
            purchase
            it?</span>
        </div>
        <v-row justify="center" v-if="PurchaseIMEIResult">
          <v-col class="shrink">
            <v-btn @click="add" color="success">Yes</v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn @click="close" color="error">No</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table dense :headers="headers" sort-by="date" :sort-desc="false" hide-default-footer
          :items="PurchaseIMEIResult.Log">
          <template v-slot:item="{ item }">
            <tr :style="{ 'background-color': color(item) }">
              <td class="text-left pointer" style="text-decoration: underline; color:blue" @click="view(item)">
                {{ item.createdAt | moment("L") }}
              </td>

              <td>
                <span>{{ item.message }}</span>
              </td>
              <td>
                {{ item.type | typeFilter }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Hashids from "hashids";
const hashids = new Hashids();
export default {
  props: ["purchaseDialog", "PurchaseIMEIResult"],
  data() {
    return {
      dialog: false,
      productStatus: null,
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "date",
          sortable: true,
        },

        {
          text: "MESSAGE",
          align: "center",
          value: "qty",
          sortable: true,
        },
        {
          text: "TYPE",
          align: "left",
          value: "name",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    purchaseDialog(val) {
      this.dialog = val;
      if (val) {
        this.productStatus = this.PurchaseIMEIResult;
      }
    },
  },
  filters: {
    typeFilter(val) {
      let value = "";
      switch (val) {
        case "purchase":
          value = "Purchase";
          break;
        case "invoice":
          value = "Invoice";
          break;
        case "returnToVendor":
          value = "Return To Vendor";
          break;
        case "restock":
          value = "Restock";
          break;
        case "markItSold":
          value = "Mark It Sold";
          break;
        case "refund":
          value = "Refund";
          break;
        case "invoiceDeleted":
          value = "Serail Was Deleted";
          break;
        default:
          break;
      }
      return value;
    },
  },
  methods: {
    add() {
      this.$emit('add', this.PurchaseIMEIResult.imeiNumber)
    },
    
    color(item) {
      let color = "white";

      if (item.type === "purchase") color = "#E1BEE7";
      if (item.type === "returnToVendor") color = "#BBDEFB";
      if (item.type === "invoice") color = "#A5D6A7";
      if (item.type === "return") color = "#FF7043";
      if (item.type === "refund") color = "#da0000";
      if (item.type === "restock") color = "#29B6F6";
      if (item.type === "markItSold") color = "#FFEE58";
      if (item.type === "invoiceDeleted") color = "#ff0080";

      return color;
    },
    close() {
      this.$emit("close");
    },
    viewPurchase(id, search) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/purchase/${hashId}/packingSlip?serialNumber=${search}`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewInvoice(id) {
      const hashId = hashids.encode(id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    view(item) {
      if (item.type === "invoice" || item.type === "refund") {
        this.viewInvoice(item.referenceNumber);
      }
      if (item.type === "purchase" || item.type === "returnToVendor") {
        this.viewPurchase(item.referenceNumber, this.productStatus.imeiNumber);
      }
    },
  },
};
</script>

<style scoped>

</style>
